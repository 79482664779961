* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "League Spartan", sans-serif;
  color: #ffffff;
}

body {
  background-color: #191919;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3% 5% 0 5%;
  font-weight: 400;
  font-size: 1rem;
}

.site-logo {
  width: 100px;
}

.nav-menu {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.nav-menu-link,
.blog-link {
  text-decoration: none;
}

.nav-menu-link:hover,
.resume-link:hover {
  color: #5fa052;
}

.contact-button {
  padding: 10px 25px;
  border: 1px solid #ffffff;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
}

.contact-button:hover,
.options-list li:hover {
  background-color: #5fa052;
}

.landing-hero {
  padding: 10%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-size: 1.25rem;
  font-weight: 400;
}

.landing-hero-header {
  font-weight: 700;
  font-size: 5.5rem;
}

.name-color {
  position: relative;
  color: #5fa052;
}

.name-color::before {
  content: "";
  right: -1px;
  top: 50%;
  position: absolute;
  height: 70px;
  width: 2px;
  background-color: #5fa052;
  transform: translateY(-50%);
  animation: blink 1s infinite; /* Adjusted the animation duration */
}

.name-color.stop-blinking::before {
  animation: none;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.landing-hero-subheader {
  font-size: 1.5rem;
  font-weight: 400;
}

.contact-resume {
  display: flex;
  align-items: center;
  gap: 30px;
}

.hero-button {
  padding: 10px 25px;
  border: 1px solid #ffffff;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
}

.hero-button:hover {
  background-color: #5fa052;
}

.resume-link {
  font-size: 400;
  font-weight: 1.25rem;
  cursor: pointer;
  text-decoration: none;
}

.tech-stack {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15%;
}

.tech-stack-icon {
  width: 50px;
  height: auto;
}

.mongo-figma-icon {
  width: 30px;
  height: auto;
}

.landing-myworks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5%;
  gap: 100px;
}

.landing-myworks > h2,
.landing-myworks > h3 {
  width: 100%;
  font-weight: 700;
  font-size: 1.5rem;
}

.img-icon {
  width: 80%;
  height: auto;
}

.landing-preview {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  align-items: flex-start;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 24px;
  justify-content: center;
}

.landing-preview > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  cursor: pointer;
}

.landing-preview-img-wrapper {
  width: 50%;
  height: 100%;
}

.preview-header,
.preview-subheader {
  font-size: 1.25rem;
}

.see-more-icon {
  width: 5%;
  height: auto;
}

.see-all {
  text-decoration: none;
  color: #5fa052;
  font-size: 1.25rem;
  font-weight: 400;
}

.soft-skills {
  width: 100%;
}

.soft-skills > ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-weight: 400;
  font-size: 1.25rem;
  gap: 30px;
  padding-bottom: 5%;
  width: 100%;
  text-align: center;
}

.footer {
  background-color: #353535;
  display: flex;
  align-items: flex-start;
  padding: 10%;
  justify-content: space-between;
  font-weight: 400;
  font-size: 1.5rem;
}

.footer-links-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 13%;
}

.footer-links {
  display: flex;
  flex-direction: column;
  gap: 40px;
  font-size: 1.25rem;
}

.get-in-touch > h4 {
  font-size: 1.25rem;
  font-weight: 400;
}

.footer-socials {
  display: flex;
  gap: 10px;
  padding-top: 15%;
  cursor: pointer;
}

.footer-social-icon {
  width: 50px;
}

.footer-whatsapp-icon {
  width: 44px;
}

.blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  padding: 10%;
  /* width: 100%; */
  /* overflow-x: hidden; */
}

.blog-preview {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 24px;
  justify-content: center;
  align-items: center;
}

.blog-link {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.landing-img-wrapper {
  width: 350px;
  height: 350px;
  overflow: hidden;
}

.blog-img-wrapper {
  width: 350px;
  height: 250px;
  /* overflow: hidden; */
  /* position: relative; */
}

.blog-post-img-wrapper {
  width: 800px;
  height: 500px;
  background-color: green;
}

.blog-img,
.landing-preview-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* display: block; */
}

.blog-post {
  display: flex;
  flex-direction: column;
  padding: 10%;
  gap: 100px;
  align-items: center;
  justify-content: center;
}

.blog-post > h1 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 120%;
}

.blog-post-img {
  height: auto;
  width: 100%;
  padding-right: 50%;
}

.blog-post-wrapper {
  display: flex;
  flex-direction: column;
  gap: 100px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 40px;
  /* padding-right: 20%; */
  width: 80%;
  white-space: pre-wrap;
  align-items: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 220px;
  font-size: 1.25rem;
  font-weight: 400;
}

.selected-option {
  padding: 10px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
}

.dropdown-icon {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.options-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: transparent;
  border: 1px solid white;
  border-top: none;
  display: none;
  background-color: #191919;
}

.options-list li {
  padding: 10px;
  cursor: pointer;
  color: white;
}

.dropdown.open .options-list {
  display: block;
}

.portfolio-dropdown {
  width: 100%;
}

.design-preview {
  width: 100%;
  /* margin: 20px auto; */
  columns: 3;
  column-gap: 20px;
}

.design-img-wrapper {
  width: 100%;
  margin-bottom: 10px;
  /* break-inside: avoid; */
}

.design-img {
  max-width: 100%;
  border-radius: 0px;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.design-img:hover {
  transform: scale(1.02);
}

.about,
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10%;
  gap: 20px;
}

.about-wrapper {
  display: flex;
  align-items: flex-start;
  padding: 10% 0;
  gap: 50px;
}

.about-wrapper > p {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 48px;
}

.about-img-icon {
  width: 30%;
}

.contact-wrapper {
  display: flex;
  align-items: center;
  padding: 10% 0;
  gap: 10%;
  width: 100%;
}

.contact-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.contact-input-box {
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
  padding: 16px 20px;
  width: 100%;
  background-color: #191919;
}

.contact-input-box:focus,
.contact-input-box-message:focus {
  outline: none;
  border: 1px solid #5fa052;
}

.contact-input-box-message {
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 200;
  padding: 16px 20px;
  width: 100%;
  height: 150px;
  resize: none;
  background-color: #191919;
}

.contact-submit-button {
  background-color: #5fa052;
  border: none;
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 400;
  padding: 10px 25px;
  border-radius: 20px;
  cursor: pointer;
}

.contact-submit {
  width: 100%;
}

.contact-details {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 30px;
  width: 100%;
}

.contact-details > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-details > div > h3 {
  font-size: 1.5rem;
  font-weight: 700;
}

.contact-details > div > p {
  font-size: 1.25rem;
  font-weight: 400;
}

.sidenav {
  height: auto;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #191919;
  overflow-x: hidden;
  transition: 0.5s;
}

.sidenav.open {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 12% 8%;
  background-color: #191919;
}

nav {
  display: flex;
  flex-direction: column;
  gap: 15%;
  width: 100%;
}

.toggle-btn {
  cursor: pointer;
  background: none;
  border: none;
}

.side-nav-open-icon {
  width: 8%;
  border: none;
  background: none;
  cursor: pointer;
}

.side-nav-close-icon {
  width: 8%;
  margin-left: auto;
  cursor: pointer;
}

.sidenav-link-list {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.side-nav-link {
  text-decoration: none;
  font-size: 1.25rem;
  color: #ffffff;
  font-weight: 400;
}

.error-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  gap: 20px;
}

.error-404-header {
  font-size: 4.5rem;
  font-weight: 700;
  text-align: center;
}

.error-404-subheader {
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
}

.error-404-message {
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
}

.error-404-home-button {
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 400;
  align-items: center;
  background-color: #5fa052;
  padding: 10px 25px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

/* Responsive design */

/* Default styles for all screen sizes */
@media (max-width: 599px) {
  /* Styles for small screens go here */
  .navbar {
    padding: 10% 8%;
  }

  .site-logo {
    width: 60px;
  }

  .nav-menu {
    display: none;
  }

  .side-nav-link {
    font-size: 1.25rem;
  }

  .contact-button {
    display: none;
  }

  .landing-hero {
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1rem;
  }
  .landing-hero-header {
    font-size: 2rem;
  }

  .name-color::before {
    height: 25px;
  }

  .landing-hero-subheader {
    font-size: 1rem;
    padding: 0 5%;
  }

  .contact-resume {
    flex-direction: column;
  }

  .hero-button {
    font-size: 1rem;
  }

  .tech-stack-icon {
    width: 30px;
  }
  .mongo-figma-icon {
    width: 20px;
  }

  .tech-stack {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    gap: 30px;
    row-gap: 10px;
  }

  .landing-myworks {
    align-items: center;
    padding-top: 20%;
    gap: 80px;
  }

  .landing-myworks > h2,
  .landing-myworks > h3 {
    text-align: center;
    font-size: 1rem;
  }

  .landing-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    padding: 0 5%;
    gap: 100px;
  }

  .landing-preview > div {
    text-align: center;
    align-items: center;
  }

  .preview-header,
  .preview-subheader {
    font-size: 1rem;
  }

  .see-more-icon {
    width: 10%;
  }

  .see-all {
    font-size: 1rem;
  }

  .soft-skills > ul {
    grid-template-columns: repeat(2, 1fr);
    font-size: 1rem;
    gap: 30px;
    text-align: left;
    padding: 0 8%;
  }

  .footer {
    flex-direction: column;
    font-size: 1.25rem;
    width: 100%;
    gap: 55px;
    padding: 10% 15%;
  }

  .footer-links-wrapper {
    padding: 0 10%;
  }

  .footer-links {
    font-size: 1rem;
    gap: 25px;
  }

  .footer-socials {
    padding-top: 10%;
    gap: 15px;
  }

  .footer-social-icon {
    width: 40px;
  }

  .footer-whatsapp-icon {
    width: 34px;
  }

  .blog > h1 {
    font-size: 1rem;
  }

  .blog-preview {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    line-height: 24px;
  }

  .dropdown {
    font-size: 1rem;
    width: 200px;
  }

  .design-preview {
    columns: 1;
  }

  .about > h1,
  .contact > h1 {
    font-size: 1rem;
  }

  .about-wrapper {
    flex-direction: column-reverse;
    align-items: center;
  }

  .about-wrapper > p {
    line-height: 25px;
    font-size: 1rem;
  }

  .about-img-icon {
    width: 100%;
  }

  .contact-wrapper {
    flex-direction: column;
    gap: 50px;
  }

  .contact-input-box,
  .contact-input-box-message {
    font-size: 1rem;
  }

  .contact-submit {
    display: flex;
    justify-content: center;
  }

  .contact-submit-button {
    font-size: 1rem;
  }

  .contact-details > div > h3 {
    font-size: 1.25rem;
  }

  .contact-details > div > p {
    font-size: 1rem;
  }

  .blog-link > h3 {
    text-align: left;
  }

  .blog-post {
    gap: 50px;
  }

  .blog-post > h1 {
    font-size: 1.25rem;
  }

  .blog-post-wrapper {
    gap: 50px;
  }

  .blog-post-img-wrapper {
    width: 300px;
    height: 250px;
  }

  .blog-post-img {
    padding-right: 0%;
  }

  .blog-post-wrapper {
    font-size: 1rem;
    line-height: 30px;
    /* padding-right: 0%; */
    width: 100%;
  }

  .error-404-header {
    font-size: 4rem;
  }

  .error-404-subheader {
    font-size: 1.5rem;
  }

  .error-404-message {
    font-size: 1rem;
  }

  .error-404-home-button {
    font-size: 1rem;
  }
}

/* Responsive styles for screens between 600px and 1049px */
@media (min-width: 600px) and (max-width: 1049px) {
  /* Styles for medium screens go here */
  .navbar {
    padding: 5%;
  }

  .site-logo {
    width: 80px;
  }

  .nav-menu {
    display: none;
  }

  .contact-button {
    display: none;
  }

  .landing-hero {
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1rem;
  }
  .landing-hero-header {
    font-size: 3rem;
  }

  .name-color::before {
    height: 35px;
  }

  .landing-hero-subheader {
    font-size: 1rem;
  }

  .contact-resume {
    flex-direction: column;
  }

  .hero-button {
    font-size: 1rem;
  }

  .tech-stack-icon {
    width: 40px;
  }
  .mongo-figma-icon {
    width: 20px;
  }

  .tech-stack {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    gap: 20%;
    row-gap: 30%;
  }

  .landing-myworks {
    align-items: center;
    padding-top: 20%;
    gap: 70px;
  }

  .landing-myworks > h2,
  .landing-myworks > h3 {
    text-align: center;
    font-size: 1.25rem;
  }

  .landing-preview {
    grid-template-columns: repeat(2, 1fr);
    font-size: 0.9rem;
    padding-left: 0;
    gap: 30px;
  }

  .landing-preview > div {
    width: 100%;
  }

  .preview-header,
  .preview-subheader {
    font-size: 0.9rem;
  }

  .see-more-icon {
    width: 7%;
  }

  .see-all {
    font-size: 1rem;
  }

  .soft-skills > ul {
    grid-template-columns: repeat(2, 1fr);
    font-size: 1rem;
    gap: 30px;
    text-align: center;
    padding: 0 8%;
  }

  .footer-links-wrapper {
    padding: 0 10%;
  }

  .footer-links {
    font-size: 1rem;
    gap: 25px;
  }

  .footer-socials {
    padding-top: 10%;
  }

  .footer-social-icon {
    width: 30px;
  }

  .footer-whatsapp-icon {
    width: 24px;
  }

  .side-nav-open-icon,
  .side-nav-close-icon {
    width: 4%;
  }

  .sidenav.open {
    padding: 6% 5% 6% 10%;
  }

  .blog > h1 {
    font-size: 1.5rem;
  }

  .blog-link > h3 {
    text-align: left;
  }

  .blog-preview {
    grid-template-columns: repeat(2, 1fr);
    gap: 70px;
    font-size: 1rem;
  }

  .blog-img-wrapper {
    width: 300px;
    height: 200px;
  }

  .blog-post-img-wrapper {
    width: 600px;
    height: 400px;
  }

  .dropdown {
    font-size: 1rem;
    width: 190px;
  }

  .design-preview {
    columns: 2;
  }

  .about > h1,
  .contact > h1 {
    font-size: 1.5rem;
  }

  .about-wrapper {
    flex-direction: column-reverse;
    align-items: center;
  }

  .about-wrapper > p {
    line-height: 25px;
    font-size: 1rem;
  }

  .about-img-icon {
    width: 100%;
  }

  .contact-wrapper {
    flex-direction: column;
    gap: 50px;
  }

  .contact-input-box,
  .contact-input-box-message,
  .contact-submit-button,
  .contact-details > div > p {
    font-size: 1rem;
  }

  .contact-submit {
    display: flex;
    justify-content: center;
  }

  .contact-details > div > h3 {
    font-size: 1.25rem;
  }

  .blog-post {
    gap: 50px;
  }

  .blog-post > h1 {
    font-size: 1.5rem;
  }

  .blog-post-img {
    padding-right: 0%;
  }

  .blog-post-wrapper {
    gap: 50px;
  }

  .blog-post-wrapper > p > pre {
    font-size: 1rem;
    line-height: 30px;
    padding-right: 0%;
  }

  .error-404-header {
    font-size: 4.5rem;
  }

  .error-404-subheader {
    font-size: 2rem;
  }

  .error-404-message {
    font-size: 1.25rem;
  }

  .error-404-home-button {
    font-size: 1.25rem;
  }
}

/* Responsive styles for screens 1050px and larger */
@media (min-width: 1050px) {
  .side-nav-open-icon {
    display: none;
  }
}
